/**
 * Given an array of strings and a single string, the function returns
 * an array of strings that were found within the long string, comparing the
 * array of strings.
 * @param longString
 * @param stringArray
 */
export function findMatchStrings(longString: string, stringArray: string[]) {
  const foundStrings: string[] = []

  stringArray.forEach((str) => {
    if (longString.includes(str)) {
      foundStrings.push(str)
    }
  })
  return foundStrings
}

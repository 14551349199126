import { ValueOrFunction, ValueType } from '@/types'

/**
 *
 * @param getter
 * @param args
 */
export function valueOrGetter<R extends ValueType>(getter: ValueOrFunction<R>, ...args: unknown[]): R {
  if (typeof getter === 'function') {
    return getter.call(window, ...args)
  }

  return getter
}

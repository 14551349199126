import { ValueType } from '@/types'

/**
 * Helper function to validate if a key exists in a certain object
 * and if that key has a certain value
 * @param obj
 * @param key
 * @param value
 */
export function hasKeySetTo(obj: { [key: string | number]: unknown }, key: string | number, value: ValueType) {
  return obj.hasOwnProperty(key) && obj[key] === value
}

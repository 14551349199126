import { apiBusyState } from '@/data/states'
import { ProcessData, RobotSystem } from '@/interfaces'
import { AuthenticationInformation, oidcEntity } from '@/oidc'
import { apiHost } from '@/utils/endpoints'
import { Configuration as ResourceConfiguration, DownloadV1Api } from 'hcosmos-api-content'
import {
  ComponentV1Api,
  Configuration,
  ConfigurationParameters,
  FilesV1Api,
  GroupV1Api,
  InternalV1Api,
  LicenseV1Api,
  OrganizationV1Api,
  RobotIssue,
  SystemV1Api,
  UserV1Api,
  HfxVersionV1Api,
} from 'hcosmos-api-data'
import { AttachmentApi, Configuration as ServiceConfiguration, IssueApi } from 'hcosmos-api-service'
import { User } from 'oidc-client-ts'
import { App, ref, Ref } from 'vue'
import { Configuration as OldConfiguration, InboundLinkControllerApi, InstanceControllerApi } from './api'

let configurationParameters: ConfigurationParameters = {
  basePath: apiHost(),
  accessToken,
}

// Configuration for the data apis
let configuration = new Configuration(configurationParameters)
let user!: AuthenticationInformation

// Configuration for the old apis(local)
let configurationOld = new OldConfiguration(configurationParameters)

// Configuation for the service apis
let serviceConfiguration = new ServiceConfiguration(configurationParameters)

let resourceConfiguration = new ResourceConfiguration(configurationParameters)

//horstfxweb
export let inboundLinkApi = new InboundLinkControllerApi(configurationOld)
export let instanceControllerApi = new InstanceControllerApi(configurationOld)

//data
export let systemApi = new SystemV1Api(configuration)
export let userApi = new UserV1Api(configuration)
export let issueApi = new IssueApi(serviceConfiguration)
export let attachmentApi = new AttachmentApi(serviceConfiguration)
export let filesApi = new FilesV1Api(configuration)
export let componentApi = new ComponentV1Api(configuration)
export let internalApi = new InternalV1Api(configuration)
export let licenseApi = new LicenseV1Api(configuration)
export let organizationsAPI = new OrganizationV1Api(configuration)
export let groupsApi = new GroupV1Api(configuration)
export let downloadApi = new DownloadV1Api(resourceConfiguration)
export let hfxVersionAPI = new HfxVersionV1Api(configuration)
export * from '@/data/states'

const selectedSystem: Ref<RobotSystem | null> = ref(null)
export const userSystems: Ref<RobotSystem[]> = ref([])
const availableRobotIssues: Ref<RobotIssue[]> = ref([])

const robotProcessData: Ref<ProcessData[]> = ref([])

export default {
  install(app: App): void {
    user = oidcEntity

    user.addUserChangeHandler((user: User | null) => {
      if (!user) {
        userSystems.value = []
        selectedSystem.value = null

        //disable sending the authentication token for a logged out user TODO find a better method
        var newConfigurationParameters: ConfigurationParameters = {
          ...configurationParameters,
        }
        newConfigurationParameters.accessToken = undefined
        reinitAPIs(newConfigurationParameters)
      } else {
        //enable sending the authentication token for a logged out user TODO find a better method
        var newConfigurationParameters: ConfigurationParameters = {
          ...configurationParameters,
        }
        newConfigurationParameters.accessToken = accessToken
        reinitAPIs(newConfigurationParameters)
      }
    })

    app.provide('selectedSystem', selectedSystem)
    app.provide('availableRobotIssues', availableRobotIssues)
    app.provide('robotProcessData', robotProcessData)
  },
}

//reinizalizes APIS with new connection parameters (e.g. if the users changes)
function reinitAPIs(configurationParameters: ConfigurationParameters) {
  configuration = new Configuration(configurationParameters)
  configurationOld = new OldConfiguration(configurationParameters)
  serviceConfiguration = new ServiceConfiguration(configurationParameters)

  inboundLinkApi = new InboundLinkControllerApi(configurationOld)
  instanceControllerApi = new InstanceControllerApi(configurationOld)

  //data
  systemApi = new SystemV1Api(configuration)
  userApi = new UserV1Api(configuration)
  issueApi = new IssueApi(serviceConfiguration)
  attachmentApi = new AttachmentApi(serviceConfiguration)
  filesApi = new FilesV1Api(configuration)
  componentApi = new ComponentV1Api(configuration)
  internalApi = new InternalV1Api(configuration)
  licenseApi = new LicenseV1Api(configuration)
  organizationsAPI = new OrganizationV1Api(configuration)
  groupsApi = new GroupV1Api(configuration)
}

export function accessToken() {
  if (!user.isAuthenticated) {
    return ''
  }

  return user.user ? 'Bearer ' + user.user.access_token : ''
}

export let initialSystemLoad = false
export let initialRobotIssuesLoad = false
export const systemLoadInProgress = ref(false)
export let issueLoadInProgress = false
export let initialIssueLoad = false

//TODO - remake method
export async function loadAvailableRobotIssues(): Promise<void> {
  apiBusyState.internal = true
  //TODO efficient
  try {
    if (!user.isAuthenticated) {
      console.log('attempted system load without user login')
      const handler = (newUser: any) => {
        if (newUser) {
          loadAvailableRobotIssues()
          user.removeUserChangeHandler(handler)
        }
      }
      user.addUserChangeHandler(handler)
      return
    }
    const robotIssues = await internalApi.listAvailableRobotIssues()
    initialRobotIssuesLoad = true
    availableRobotIssues.value = robotIssues
  } catch (e) {
    // failure(e)
  }
  apiBusyState.system = false
}

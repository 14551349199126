
/* eslint-disable vue/no-setup-props-destructure */
import { defineComponent, PropType, ref } from 'vue'
import { uiOptions } from '@/config'

export default defineComponent({
	name: 'ConfirmationMessage',
	props: {
		title: {
			type: String as PropType<string>,
			required: true,
		},
		message: {
			type: String as PropType<string>,
			default:
				'Ihre Anfrage ist bei uns eingegangen. Sie werden in Kürze ein Angebot für die angefragte Leistung per E-mail erhalten.',
		},
	},
	setup({ title }) {
		let titleArray = title.split(' ')
		const titleFirstWord = ref(titleArray[0])
		const [, ...rest] = titleArray
		const titleFormatted = ref(rest.join(' '))
		return { titleFirstWord, titleFormatted, uiOptions }
	},
})

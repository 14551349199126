import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  class: "menu icons icon-top",
  role: "menubar"
}
const _hoisted_3 = ["id"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", {
    class: "grid-x navbar",
    "aria-label": _ctx.$t('messages.navbar.label'),
    style: _normalizeStyle([_ctx.hasSidebar ? { 'justify-content': 'space-between' } : '', _ctx.envBackground])
  }, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredElements, (element, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "navbar-item",
          key: index,
          role: "menuitem",
          id: `nav-${element.id}-item`
        }, [
          (element.externalLink)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: element.externalLink,
                target: "_blank"
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass([element.icon, "bi"]),
                  style: _normalizeStyle({ fontSize: _ctx.mobileSizeIcons }),
                  "aria-hidden": "true"
                }, null, 6),
                (element.titleLocale)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t(element.titleLocale)), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(element.title), 1))
              ], 8, _hoisted_4))
            : _createCommentVNode("", true),
          (element.link)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: element.link,
                class: _normalizeClass({ selected: _ctx.activeElement.title.startsWith(element.title), [element.class || '']: 'true' })
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: _normalizeClass([element.icon, "bi"]),
                    style: _normalizeStyle({ fontSize: _ctx.mobileSizeIcons }),
                    "aria-hidden": "true"
                  }, null, 6),
                  (element.titleLocale)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t(element.titleLocale)), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(element.title), 1))
                ]),
                _: 2
              }, 1032, ["to", "class"]))
            : _createCommentVNode("", true)
        ], 8, _hoisted_3))
      }), 128))
    ]),
    (_ctx.hasSidebar)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ui.toggleSidebar && _ctx.ui.toggleSidebar(...args))),
          class: "chevron"
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["bi bi-chevron-up chevron-animation", { open: _ctx.ui.isSidebarOpen }])
          }, null, 2)
        ]))
      : _createCommentVNode("", true)
  ], 12, _hoisted_1))
}
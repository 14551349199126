import { Attachment } from '@/interfaces'
import { GetAttachmentContentRequest } from 'hcosmos-api-service'
import { attachmentApi } from '@/data'
import { useToast } from 'vue-toastification'
import i18n from '@/locales/i18n'
import { addApiDynamicBusyState, removeApiDynamicBusyState } from '.'

const toast = useToast()
const { t } = i18n.global

export async function externalDownloadLink(attachment: Attachment) {
  const request: GetAttachmentContentRequest = {
    id: attachment.id,
  }
  try {
    addApiDynamicBusyState(`attachment-${attachment.id}`)
    toast.info(t('messages.modals.fileInfo.downloadFileName', { fileName: attachment.fileName }))
    const response = await attachmentApi.getAttachmentContent(request)
    const fileURL = window.URL.createObjectURL(response)
    const fileLink = document.createElement('a')
    fileLink.href = fileURL
    fileLink.setAttribute('download', attachment.fileName)
    document.body.appendChild(fileLink)
    fileLink.click()
  } catch (error) {
    if (error instanceof Error) {
      console.error(error)
      toast.error(error.message)
    } else {
      console.error(error)
      toast.error(t('messages.toast.errorServer'))
    }
  } finally {
    removeApiDynamicBusyState(`attachment-${attachment.id}`)
  }
}

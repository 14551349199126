
import MainContent from '@/components/App/MainContent.vue'
import Navbar from '@/components/App/Navbar'
import Topbar from '@/components/App/Topbar.vue'
import { defineComponent } from 'vue'
import { useAuthStore } from './stores/auth/authStore'
import { useOrganizationsStore } from './stores'

export default defineComponent({
  components: {
    Topbar,
    Navbar,
    MainContent,
  },
  setup() {
    const { whenAuthenticated } = useAuthStore()
    const { loadOrganizationsList } = useOrganizationsStore()

    whenAuthenticated().then(async () => {
      await loadOrganizationsList()
    })

    return {}
  },
})

import { deLocationOptions, enLocationOptions, uiOptions } from '@/config'
import { DeviceOrientation } from '@/enums'
import i18n, { loadLocalMessages } from '@/locales/i18n'
import { AuthenticationInformation, oidcEntity } from '@/oidc'
import { NavigationGuardNext, RouteLocationNormalized, RouteLocationRaw, Router } from 'vue-router'
import { useToast } from 'vue-toastification'
import { AuthenticationConfig } from './authenticationConfigs'

const { t } = i18n.global
const user = oidcEntity

/**
 *
 * @param to
 * @param from
 * @param next
 */
export function sendToMobileView(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const routeName = (to.name || '') as string
  if (isMobile() && !['HomeMobile', 'HorstFXWeb', 'Horstfx-Options'].includes(routeName)) {
    next({ name: 'HomeMobile' })
  } else {
    next()
  }
}

export function createLanguageQueryCheck(router: Router) {
  return function checkLanguageQuery(target: RouteLocationNormalized) {
    const { query } = target
    const langs = Object.keys(loadLocalMessages())
    if ('lang' in query) {
      const existsLang = langs.some((lang) => lang === query.lang)
      if (existsLang && query.lang !== null) {
        i18n.global.locale = query.lang.toString()
        switch (query.lang) {
          case 'de':
            uiOptions().changeLanguageLocationOptions(deLocationOptions)
            break
          case 'en':
            uiOptions().changeLanguageLocationOptions(enLocationOptions)
            break
          default:
            uiOptions().changeLanguageLocationOptions(enLocationOptions)
            break
        }
      }
      router.push(target.path)
    } else {
      return
    }
  }
}

export function createAuthenticate(
  router: Router,
  user: AuthenticationInformation,
  toast: ReturnType<typeof useToast>,
  fallback: RouteLocationRaw = { name: 'Home' },
) {
  return function authenticate(target: RouteLocationNormalized) {
    // console.log({
    //   user: {
    //     initialized: user.initialized,
    //     isAuthenticated: user.isAuthenticated,
    //     isInternalUser: user.isInternalUser,
    //   },
    // })
    if (!user.initialized) {
      console.error('Initial authentication call not yet done')
      return
    }

    target.matched.forEach((route) => {
      try {
        if (route.meta && route.meta.authentication) {
          const { authenticated, internalUser } = route.meta.authentication as AuthenticationConfig
          if (authenticated && !user.isAuthenticated) {
            // User not authenticated redirect to login
            router.push(fallback)
            toast.error(t('messages.toast.authenticationRequired'))
          }
          if (internalUser && !user.isInternalUser) {
            // Not internal user, redirect to login
            router.push(fallback)
            toast.error(t('messages.toast.interUser'))
          }
        } else {
          console.error('Route does not have authentication configuration in meta')
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error(error)
          toast.error(error.message)
        } else {
          console.error(error)
          toast.error(t('messages.toast.errorServer'))
        }
      }
      checkSidebar(target)
      // Authentication executed
    })
  }
}

export function checkSidebar(target: RouteLocationNormalized) {
  if (target.matched.some((record) => record.meta.withSidebar) && user.initialized && user.isAuthenticated) {
    const defaultOpen = target.meta.defaultOpen
    //openSideBar()
    uiOptions().isSidebarOpen = defaultOpen !== undefined ? (defaultOpen as boolean) : true
  } else {
    removeSideBar()
  }
}

/**
 * Determine if device is a mobile phone or not, max with of the device must be 914px
 * this covers devices such as:
 * Samsung Galaxy A51/71;S20 Ultra
 * Iphone XR;12 Pro; 6/7/8 Plus
 * Pixel 5
 * @returns
 */
export function isMobile() {
  const matchMobilePointer = window.matchMedia('(pointer:coarse)')
  const matchMobileWidth = window.matchMedia('(max-width: 915px)')
  if (isTablet()) {
    return false
  } else {
    return matchMobilePointer.matches && matchMobileWidth.matches
  }
}

/**
 * Determines wether the device is a Table or not
 * @returns
 */
export function isTablet() {
  const userAgent = navigator.userAgent.toLowerCase()
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent,
  )
}

export function determineOrientation() {
  const mediaOrientation = window.matchMedia('(orientation: portrait)')
  const { PORTRAIT, LANDSCAPE } = DeviceOrientation
  if (isMobile()) {
    return { orientation: mediaOrientation.matches ? PORTRAIT : LANDSCAPE, mediaOrientation }
  }
}

function removeSideBar() {
  if (uiOptions().isSidebarOpen) {
    uiOptions().toggleSidebar()
  }
}

function openSideBar() {
  if (!uiOptions().isSidebarOpen) {
    uiOptions().toggleSidebar()
  }
}

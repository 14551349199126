/**
 *
 * @param inputField
 * @param mapTabIds
 */
export function getTabIdFromInputField(inputField: string, mapTabIds: Map<string, string[]>) {
  let tabId: null | string = null

  for (const [keys, values] of mapTabIds.entries()) {
    values.includes(inputField) ? (tabId = keys) : null
  }
  return tabId
}

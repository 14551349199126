import { HfxVersion, SimpleHfxVersion } from '@/interfaces'
import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { loadHfxVersions } from './loadHfxVersions'
import { loadFCUVersion } from './loadFCUVersion'
import { DateTime } from 'luxon'
import { faker } from '@faker-js/faker'
import { fetchFullVersionList } from './fetchFullVersionsList'
import { pinia } from '..'
import { HorstFXErrorOptions } from '@/types'
import { loadHfxErrorOptions } from './loadHfxErrorOptions'
import { horstFXErrors } from '@/data/systems'

export const useInternalStore = defineStore(
  'internalStore',
  () => {
    const versions = ref<HfxVersion[] | null>(null)
    const fcu = ref<HfxVersion | null>(null)
    const versionsList = ref<SimpleHfxVersion[] | null>(null)
    const horstFXErrorOptions = ref<HorstFXErrorOptions | null>(null)

    return {
      versions,
      fcu,
      versionsList,
      horstFXErrorOptions,
      loadVersions: () => loadHfxVersions(versions),
      loadFCU: () => loadFCUVersion(fcu),
      loadVersionsList: () => fetchFullVersionList(versionsList),
      loadHfxErrorOptions: () => loadHfxErrorOptions(horstFXErrorOptions),
    }
  },
  {
    authenticated: {
      loadFCU: true,
      loadVersions: true,
      loadVersionsList: true,
      loadHfxErrorOptions: true,
    },
    dummyValue: {
      loadFCU: async () => null,
      loadVersions: async () => {
        return [
          // {
          //   version: '2022.01',
          //   languages: ['de'],
          //   current: false,
          //   languageInsensitiv: false,
          //   releaseDate: DateTime.fromJSDate(new Date('2022-01-01')),
          // },
          // {
          //   version: '2022.04',
          //   languages: ['de', 'en'],
          //   current: false,
          //   languageInsensitiv: false,
          //   releaseDate: DateTime.fromJSDate(new Date('2022-04-01')),
          // },
          // {
          //   version: '2022.07_hotfix3',
          //   languages: ['de', 'en'],
          //   current: false,
          //   languageInsensitiv: false,
          //   releaseDate: DateTime.fromJSDate(new Date('2022-07-01')),
          // },
          // {
          //   version: '2023.04.03',
          //   languages: ['de', 'en'],
          //   current: true,
          //   languageInsensitiv: false,
          //   releaseDate: DateTime.fromJSDate(new Date('2023-04-01')),
          // },
        ]
      },
      loadVersionsList: async () => {
        return []
      },
      loadHfxErrorOptions: async () => {
        return horstFXErrors
      },
    },
  },
)

export const injectHfxErrorOptions = () => {
  return useInternalStore(pinia).loadHfxErrorOptions()
}

export const injectFullVersionList = () => {
  return useInternalStore(pinia).loadVersionsList()
}

export const injectHfxVersions = () => {
  return useInternalStore(pinia).loadVersions()
}

export const injectFCUVersion = () => {
  return useInternalStore(pinia).loadFCU()
}

export const injectInternalRefs = () => storeToRefs(useInternalStore(pinia))

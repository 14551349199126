import { ISidebar } from '@/interfaces'
import { authenticationInformation } from '@/oidc/authentication'
import { injectSystemRefs } from '@/stores'
import { internalLink } from '@/utils/helpers'
import { RouteLocationNamedRaw } from 'vue-router'

export function overviewSidebar(): ISidebar {
  const system = injectSystemRefs().selectedSystem

  const user = authenticationInformation().user

  const enabled = () => system.value !== null
  const internal = () => user.isInternalUser

  const route = (): Partial<RouteLocationNamedRaw> => {
    return { params: { systemId: system.value?.serialNumber } }
  }

  return {
    id: 'meineRoboter',
    title: 'messages.sidebars.fleet.title',
    handler: internalLink('Fleet', route),
    icon: 'bi-chevron-left',
    sections: [
      {
        title: system.value?.attributes.nickname
          ? system.value?.attributes.nickname
          : `${system.value?.robot.model.name} / ${system.value?.serialNumber}`,
        handler: internalLink('SystemOverview', route),
        enabled,
        id: 'overview',
      },

      {
        titleLocale: 'messages.sidebars.fleet.sections.fileBrowser',
        handler: internalLink('BackupUpdate', route),
        enabled,
        id: 'backup',
        icon: 'bi-lock-fill',
      },

      {
        title: 'PREDICTIVE MAINTENANCE',
        handler: internalLink('Maintenance', route),
        enabled,
        id: 'maintenance',
        icon: 'bi-lock-fill',
      },

      {
        title: 'Fruitcore Intern',
        handler: internalLink('FleetInternal', route),
        enabled,
        visible: internal,
        id: 'internal',
      },

      {
        titleLocale: 'messages.sidebars.fleet.sections.serviceContract',
        handler: internalLink('SingleService', route),
        enabled,
        id: 'service',
      },
      {
        titleLocale: 'messages.sidebars.fleet.sections.processData',
        handler: internalLink('ProcessData', route),
        enabled: () => user.user?.profile.email === process.env.VUE_APP_MESSE_USER,
        visible: () => user.user?.profile.email === process.env.VUE_APP_MESSE_USER,
        id: 'processdata',
      },
    ],
  }
}

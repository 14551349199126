import { DateTime } from 'luxon'
import { uiOptions } from '@/config'

/**
 *
 * @param date
 * @param formatOption Format options: https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 + default format option: 'DD' localized date with abbreviated month 'Aug 6, 2014'
 */
export function convertDateToDateTimeWithFormatOption(date: Date | DateTime, formatOption = 'DD') {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date).setLocale(uiOptions().location.languageTag).toFormat(formatOption)
  }
  return date.setLocale(uiOptions().location.languageTag).toFormat(formatOption)
}

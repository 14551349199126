
import { uiOptions } from '@/config'
import { authenticationInformation } from '@/oidc/authentication'
import { isMobile } from '@/router/utils'
import { determineVisualEnvColor } from '@/utils/flags'
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { getNavElements } from './elements'
import elementsMobile from './elementsMobile'

export default defineComponent({
  name: 'Navbar',
  setup() {
    const route = useRoute()
    const user = authenticationInformation().user

    const envBackground = computed(() => {
      const { from, to } = determineVisualEnvColor()
      return {
        background: `linear-gradient(to bottom, #${from} 0%, #${to} 100%)`,
      }
    })

    const elements = computed(() => {
      return getNavElements(user)
    })

    const filteredElements = computed(() => {
      if (route.name) {
        if (uiOptions().mobileRoutes.includes(route.name.toString()) && isMobile()) {
          return elementsMobile.filter((element) => element.visible === undefined || element.visible())
        }
      }
      return elements.value.filter((element) => element.visible === undefined || element.visible())
    })

    const activeElement = computed(() => {
      const active = elements.value.find(
        (elem, index) => index !== 0 && elem.link && route.path.startsWith(elem.link as string),
      )
      return active ? active : { title: 'no', icon: 'no' }
    })

    const hasSidebar = computed(() => {
      if (!route.meta.sidebar) {
        return false
      }

      return true
    })

    const mobileSizeIcons = computed(() => {
      let sizeValue = ''
      if (route.name) {
        if (uiOptions().mobileRoutes.includes(route.name.toString())) {
          sizeValue = 'xx-large'
        }
      }
      return sizeValue
    })

    return {
      ui: uiOptions(),
      activeElement,
      filteredElements,
      hasSidebar,
      mobileSizeIcons,
      envBackground,
    }
  },
})

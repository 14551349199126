export const horstFXErrorsLocaleKeys = [
  'configurationFile',
  'connectingNotSuccessful',
  'emergencyStop',
  'externalEmergencyStop',
  'unexpectedError',
  'motorTemperature',
  'errorAcquisition',
  'writePermission',
  'loadProgramError',
  'programHeaderMissing',
  'missingLines',
]

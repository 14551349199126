export function determineVisualEnvColor(): { from: string; to: string } {
  const from = process.env.VUE_APP_VISUAL_ENV_COLOR_FROM
  const to = process.env.VUE_APP_VISUAL_ENV_COLOR_TO

  if (from && to) {
    return {
      from,
      to,
    }
  }

  return {
    from: '007079',
    to: '24b6b6',
  }
}

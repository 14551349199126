/**
 *
 * @param event
 * @param func
 */
export function enterKeyDetector(event: KeyboardEvent, func: () => void): void {
  if (event.code === 'Enter') {
    func()
  }
}

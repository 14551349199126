import { defineStore, storeToRefs } from 'pinia'
import { ServiceIssue } from '@/interfaces'
import { computed, ref } from 'vue'
import { pinia } from '..'
import { JiraIssueComment } from 'hcosmos-api-service'
import { apiBusyState } from '@/data'
import { uiOptions } from '@/config'
import { generateServiceIssueDummies, createCommentDummy, createServiceIssueDummy } from '@/data/serviceIssues'
import {
  createFetchServiceIssues,
  createFetchServiceIssueById,
  createAddCommentToServiceIssue,
  createAddAttachmentsToServiceIssue,
  createServiceIssueTicket,
} from '@/stores/serviceIssue'
import { TableServiceIssue } from '@/interfaces/TableServiceIssue'

export const useServiceIssueStore = defineStore(
  'serviceIssueStore',
  () => {
    //NOTE - TableServiceIssue is "just" a simple representation of the issue we got..
    // Because of the rate limit of Jira, we will only fetch the needed fields for the table view.
    const tableServiceIssues = ref<TableServiceIssue[]>([])
    const selectedServiceIssue = ref<null | ServiceIssue>(null)
    const selectedServiceIssueId = computed(() => selectedServiceIssue.value?.id)

    return {
      tableServiceIssues,
      selectedServiceIssue,
      selectedServiceIssueId,
      fetchServiceIssues: createFetchServiceIssues(tableServiceIssues),
      fetchServiceIssueById: createFetchServiceIssueById(selectedServiceIssue),
      addCommentToServiceIssue: createAddCommentToServiceIssue(),
      addAttachmentsToServiceIssue: createAddAttachmentsToServiceIssue(),
      createServiceIssueTicket: createServiceIssueTicket(),
    }
  },
  {
    dummyValue: {
      fetchServiceIssues: async (store) => {
        apiBusyState.issues = true
        await new Promise((resolve) => setTimeout(resolve, uiOptions().dummyDelayTime))
        const tableServiceIssues = generateServiceIssueDummies()
        store.tableServiceIssues = tableServiceIssues
        apiBusyState.issues = false
        return
      },
      fetchServiceIssueById: async (store, issueIdOrKey: string) => {
        store.selectedServiceIssue = createServiceIssueDummy(issueIdOrKey)
        return
      },
      addCommentToServiceIssue: async (store) => {
        const dummyCommentAdded: JiraIssueComment[] = [
          ...(store.selectedServiceIssue?.comments as JiraIssueComment[]),
          createCommentDummy(),
        ]
        if (store.selectedServiceIssue?.comments) {
          store.selectedServiceIssue.comments = dummyCommentAdded
        }
        return
      },
    },
    authenticated: {
      fetchServiceIssues: true,
      fetchServiceIssueById: true,
      addCommentToServiceIssue: true,
      addAttachmentsToServiceIssue: true,
      createServiceIssueTicket: true,
    },
  },
)

export type ServiceIssueStore = typeof useServiceIssueStore

export const injectServiceIssues = () => {
  return useServiceIssueStore(pinia).fetchServiceIssues()
}

export const injectServiceIssuesRefs = () => storeToRefs(useServiceIssueStore(pinia))

export const injectServiceIssue = (issueIdOrKey: string) => {
  const store = useServiceIssueStore(pinia)
  return store.fetchServiceIssueById(issueIdOrKey)
}

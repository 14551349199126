export interface AuthenticationConfig {
  authenticated: boolean
  internalUser?: boolean
}

export const authenticated: AuthenticationConfig = {
  authenticated: true,
}

export const internalUser: AuthenticationConfig = {
  authenticated: true,
  internalUser: true,
}

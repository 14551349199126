import Accordion from '@/components/App/Accordion'
import Modal from '@/components/App/Modal'
import AssetLink from '@/components/Documentation/AssetLink.vue'
import ExternalLink from '@/components/Licenses/ExternalLink.vue'
import Spinner from '@/components/Utils/Spinner.vue'
import i18n from '@/locales/i18n' // https://kazupon.github.io/vue-i18n/
import 'flatpickr/dist/flatpickr.css'
import FloatingVue from 'floating-vue' // v-tooltip https://floating-vue.starpad.dev/
import 'floating-vue/dist/style.css'
import { createApp } from 'vue'
import FlatPickr from 'vue-flatpickr-component'
import VueGtag, { bootstrap } from 'vue-gtag'
import vSelect from 'vue-select'

import Toast, { POSITION } from 'vue-toastification' // https://github.com/Maronato/vue-toastification/tree/next
import 'vue-select/dist/vue-select.css'
import 'vue-toastification/dist/index.css'
import '@/styles/foundation-customized.scss'
import '@/styles/fruitcore.scss'
import 'bootstrap-icons-fruitcore/font/bootstrap-icons.css'

import App from './App.vue'
import config from './config'
import data from './data'
import oidc from './oidc'
import router from './router'
import { pinia } from './stores'
import { isUserManagementEnabled } from '@/utils/flags'

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(oidc)
  .use(data)
  .use(config)
  .use(i18n)
  .use(FloatingVue)
  .use(Toast, { position: POSITION.BOTTOM_RIGHT }) // add PluginOptions
  .use({
    install(app) {
      const userManagementEnabled = isUserManagementEnabled()
      app.provide('featureFlags', { userManagementEnabled })
    },
  })
  // Global components
  .component('asset-link', AssetLink)
  .component('external-link', ExternalLink)
  .component('modal', Modal)
  .component('accordion', Accordion)
  .component('v-select', vSelect)
  .component('flat-pickr', FlatPickr)
  .component('spinner', Spinner)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Cookiebot = (window as any).Cookiebot

if (process.env.VUE_APP_GOOGLE_ANALYTICS_ENABLE === 'true' && Cookiebot) {
  console.log('gtag enabled')

  //prime the gtag plugin
  app.use(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    VueGtag as any,
    {
      config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
      },
      bootstrap: false,
      params: {
        anonymize_ip: true,
      },
    },
    router,
  )
  //show the cookiebanner
  window.addEventListener('CookiebotOnConsentReady', () => {
    console.log('consent ready')
    if (Cookiebot.consent.statistics) {
      console.log('user consented to statistics. Loading gtag')
      bootstrap()
    } else {
      console.log('user did not consent to statistics.')
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!Cookiebot.hasResponse) {
    console.log('No stored consent found. Showing cookie window')
    //The 'I don't care about cookies' extension removes this function, therefore we cannot show the window
    if (Cookiebot.show) {
      Cookiebot.show()
    }
  }
}

app.mount('#app')

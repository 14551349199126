import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-492e84ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-x" }
const _hoisted_2 = {
  key: 0,
  class: "grid-y cell auto",
  id: "main-content-sidebar"
}
const _hoisted_3 = {
  id: "sidebar-scroll",
  class: "cell auto"
}
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = { class: "h4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation_message = _resolveComponent("confirmation-message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.sidebar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(typeof _ctx.sidebar!.handler === 'function' ? 'button' : _ctx.sidebar.handler?.component || 'button'), _mergeProps(typeof _ctx.sidebar!.handler === 'function' ? { type: 'button' } : _ctx.sidebar!.handler?.props || {}, _toHandlers(typeof _ctx.sidebar!.handler === 'function' ? { click: _ctx.sidebar?.handler } : {}), {
              class: "button expanded icon cell sidebar-button small",
              id: "sidebar-title"
            }), {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, [
                  (_ctx.sidebar.icon)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: _normalizeClass(_ctx.sidebar.icon)
                      }, null, 2))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(_ctx.valueOrGetter(_ctx.sidebar.title))), 1)
              ]),
              _: 1
            }, 16)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleSections, (section) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(typeof section.handler === 'function' ? 'button' : section.handler.component), _mergeProps({
                key: _ctx.valueOrGetter(section.title)
              }, typeof section.handler === 'function' ? { type: 'button' } : section.handler.props || {}, _toHandlers(typeof section.handler === 'function' ? _ctx.toBindObject(section) : {}), {
                id: 'sidebar-' + section.id,
                class: ["sidebar-section button expanded", {
              'fc-disabled': !_ctx.valueOrGetter(section.enabled),
              'active-sidebar-section': _ctx.active && _ctx.active === section.id,
            }]
              }), {
                default: _withCtx(() => [
                  (section.title)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.valueOrGetter(section.title)), 1)
                      ], 64))
                    : (section.titleLocale)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_ctx.$t(_ctx.valueOrGetter(section.titleLocale))), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                  (_ctx.valueOrGetter(section.iconVisible))
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 2,
                        class: _normalizeClass(_ctx.valueOrGetter(section.icon))
                      }, null, 2))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1040, ["id", "class"]))
            }), 128))
          ]),
          (_ctx.sidebar.components)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.sidebar.components, (component, index) => {
                return (_openBlock(), _createBlock(_Teleport, {
                  key: index,
                  to: "body"
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(component))),
                  (_ctx.sidebar?.id === 'meineLizenzen')
                    ? (_openBlock(), _createBlock(_component_confirmation_message, {
                        key: 0,
                        title: _ctx.confirmationMessageTitle
                      }, null, 8, ["title"]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
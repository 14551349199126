import { ISidebar } from '@/interfaces'
import { callModal } from '@/utils/helpers'
import i18n from '@/locales/i18n'

const { t } = i18n.global

export function serviceSidebar(): ISidebar {
  return {
    id: 'meinService',
    title: 'messages.sidebars.service.title',
    sections: [
      {
        title: t('messages.sidebars.service.sections.createServiceTicket'),
        enabled: true,
        id: 'formCreateServiceTicket',
        handler: callModal('formCreateServiceTicket'),
      },
    ],
    components: ['form-create-service-ticket'],
  }
}

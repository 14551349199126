import { uiOptions } from '@/config'
import { SidebarHandler } from '@/types'

/**
 *
 * @param modal
 * @returns
 */
export function callModal(modal: string): SidebarHandler {
  return () => {
    uiOptions().toggleModal(modal)
  }
}

import { uiOptions } from '@/config'
import { AggregationMethod, AggregationUnit, DataMode } from '@/enums'
import { ProcessData, ProcessDataRecord } from '@/interfaces'
import { useSystemStore } from '@/stores'
import { faker } from '@faker-js/faker'
import { DateTime } from 'luxon'
import { aggregationUnitLookup, aggregationMethodLookup } from './'

export async function processData(
  id: string,
  aggregation: { method: AggregationMethod; unit: AggregationUnit; start: DateTime; end: DateTime },
  category: string[] = [],
  key: string[] = [],
) {
  const ui = uiOptions()
  const systemStore = useSystemStore()

  if (ui.dataMode === DataMode.DUMMY) {
    return [
      ...fakeArray(5, 10).map((_, i) => processDataDummy(aggregation.unit)),
      processDataDummy(aggregation.unit, 'test', ['foo', 'baar', 'baz']),
    ]
  } else {
    return systemStore.loadProcessData(id, {
      unit: aggregationUnitLookup(aggregation.unit)!,
      method: aggregationMethodLookup(aggregation.method)!,
      start: aggregation.start,
      end: aggregation.end,
      category,
      key,
    })
  }
}

function processDataDummy(aggregation: AggregationUnit, category?: string, keyNames?: string[]): ProcessData {
  const createFakeKeyDataEntries = (keyNames?: string[]) => {
    const keyArray: string[] = fakeArray(
      keyNames && 6 - keyNames.length > 0 ? 6 - keyNames.length : 6,
      keyNames && 12 - keyNames.length > 0 ? 12 - keyNames.length : 12,
    ).map(() => faker.word.adjective())

    return [...(keyNames || []), ...keyArray].map((key) => [
      key,
      [
        ...fakeArray(2, 3).map(() => processDataRecordStringDummy(key)),
        ...fakeArray(20, 30).map(() => processDataRecordDummy(key)),
      ].sort((a, b) => (a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0)),
    ])
  }

  return {
    category: category || faker.word.noun(),
    end: DateTime.fromJSDate(faker.date.recent(5)),
    start: DateTime.fromJSDate(faker.date.soon(2)),
    data: Object.fromEntries(createFakeKeyDataEntries(keyNames)),
    aggregation: {
      method: faker.helpers.arrayElement(Object.values(AggregationMethod)),
      unit: aggregation,
      interval: 1,
    },
  }
}

function processDataRecordDummy(key: string): ProcessDataRecord {
  return {
    key,
    value: faker.datatype.number({ max: 999 }),
    timestamp: DateTime.fromJSDate(faker.date.recent(5)),
  }
}

function processDataRecordStringDummy(key: string): ProcessDataRecord {
  return {
    key,
    string_value: faker.random.words(3),
    timestamp: DateTime.fromJSDate(faker.date.recent(5)),
  }
}

function fakeArray(min: number, max: number): number[] {
  return faker.datatype.array(faker.datatype.number({ min, max })).map((never, i) => i)
}

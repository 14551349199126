import { GroupLicense } from '@/interfaces'
import { pinia, useUserManagementStore } from '@/stores'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { createAttachLicenseToGroup } from './attachLicenseToGroup'
import { createLoadLicenses } from './loadLicenses'

export const useLicensesStore = defineStore(
  'licensesStore',
  () => {
    const licenses = ref<Map<string, GroupLicense[]>>(new Map())
    const userManageMentStore = useUserManagementStore()

    const loadLicenses = createLoadLicenses(licenses)
    const attachLicenseToGroup = createAttachLicenseToGroup(() =>
      loadLicenses(userManageMentStore.currentGroupId!, true),
    )

    const currentLicenses = computed(() => licenses.value.get(userManageMentStore.currentGroupId!) || [])

    return {
      licenses: currentLicenses,
      loadLicenses,
      attachLicenseToGroup,
    }
  },
  {
    authenticated: {
      attachLicenseToGroup: true,
      loadLicenses: true,
    },
  },
)

export const injectLicenses = (id: string) => {
  const licensesStore = useLicensesStore(pinia)
  return licensesStore.loadLicenses(id)
}

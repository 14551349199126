import { ISidebar } from '@/interfaces'
import { callModal } from '@/utils/helpers'

export function licenseSidebar(): ISidebar {
  return {
    id: 'meineLizenzen',
    title: 'messages.sidebars.license.title',
    sections: [
      {
        titleLocale: 'messages.sidebars.license.sections.reserveLicense',
        enabled: true,
        id: 'formRequestLicense',
        handler: callModal('formRequestLicense'),
      },
      {
        titleLocale: 'messages.sidebars.license.sections.reservedLicenses',
        enabled: true,
        id: 'bookedLicenses',
        handler: callModal('bookedLicenses'),
      },
      {
        titleLocale: 'messages.sidebars.license.sections.buyLicenses',
        enabled: true,
        id: 'buyDesktopLicense',
        handler: callModal('buyDesktopLicense'),
      },
    ],
    components: ['form-request-license-ticket', 'booked-licenses', 'buy-desktop-license'],
  }
}

import { oidcEntity } from '@/oidc'

export const afterAuthentication = <T extends Array<unknown>, U>(fn: (...args: T) => U | never) => {
  return (...args: T): Promise<U> => {
    return new Promise(function (resolve, reject) {
      if (oidcEntity.isAuthenticated) {
        return resolve(fn(...args))
      }

      const handler = (newUser: any) => {
        if (newUser) {
          resolve(fn(...args))
          oidcEntity.removeUserChangeHandler(handler)
        }
      }
      oidcEntity.addUserChangeHandler(handler)
    })
  }
}

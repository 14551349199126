import { ILicensePackageInformationWithStatus } from '@/interfaces'
import { Ref } from 'vue'
import { apiBusyState } from '@/data/states'
import { userApi } from '@/data'
import { LicensePackageInformation, LicenseRentalInformation } from 'hcosmos-api-data'
import { LicensePackageInformationStatusTypeEnum } from '@/enums'
import { useToast } from 'vue-toastification'
import i18n from '@/locales/i18n'

const { AVAILABLE, INUSE, EXPIRED, NotApplicable } = LicensePackageInformationStatusTypeEnum
const currentDate = new Date()
const toast = useToast()
const { t } = i18n.global

/**
 *
 * @param refPackageInformationLicenses
 */
function createFetchPackageInformationLicense(
  refPackageInformationLicenses: Ref<ILicensePackageInformationWithStatus[]>,
) {
  return async () => {
    apiBusyState.packageInformationLicenses = true
    try {
      const response = await userApi.getLicenses()
      refPackageInformationLicenses.value = response.map((packageInformationLicense) =>
        convertLicensePackageInformationWithStatus(packageInformationLicense),
      )
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        toast.error(error.message)
      } else {
        console.error(error)
        toast.error(t('messages.toast.errorServer'))
      }
    }
    apiBusyState.packageInformationLicenses = false
  }
}

/**
 * Depending on the values of the parameters 'timeStart', 'timeEnd', and 'rented'
 * from a LicensePackageInformation object, its status can be determine and return
 * an object of type LicensePackageInformationWithStatus, with status enum type
 * LicensePackageInformationStatusTypeEnum.
 * Logic of the status assignment is as follow:
 * N/A: This means that none of the parameters previously mentioned deliver valid value.
 * EXPIRED: This means that the current date is further as the END date of the LicensePackageInformation
 * INUSE: From the LicenseRentalInformation created, it gets its rental period, if the
 * current date is within this range, then this status is assigned.
 * AVAILABLE: If the LicensePackageInformation has no rental assigned or the rental period has
 * passed and it still has a valid LicensePackageInformation, then this status is assigned.
 * @param {LicensePackageInformation} licensePackageInformation
 * @returns {ILicensePackageInformationWithStatus}
 */
function convertLicensePackageInformationWithStatus(licensePackageInformation: LicensePackageInformation) {
  const licensePackageInformationWithStatus: ILicensePackageInformationWithStatus = {
    ...licensePackageInformation,
    status: NotApplicable,
  }

  const { timeEnd, timeStart, rented } = licensePackageInformationWithStatus

  if (timeEnd !== undefined && timeStart !== undefined) {
    if (currentDate > timeEnd) {
      licensePackageInformationWithStatus.status = EXPIRED
    } else {
      if (rented !== undefined) {
        const currentLicensesRented = filterCurrentLicenseRented(rented)
        if (currentLicensesRented.length > 0) {
          licensePackageInformationWithStatus.status = INUSE
        } else {
          licensePackageInformationWithStatus.status = AVAILABLE
        }
      } else {
        licensePackageInformationWithStatus.status = AVAILABLE
      }
    }
  }

  return licensePackageInformationWithStatus
}

/**
 * The function filters the LicenseRentalInformation to
 * determine if there is a current License rented.
 * It returns the last LicenseRentalInformation inserted
 * @param rentedSet
 * @returns
 */
function filterCurrentLicenseRented(rentedSet: Set<LicenseRentalInformation>) {
  const rentedArray = [...rentedSet]

  const currentRentals = rentedArray.filter((rental) => {
    const { timeStart, timeEnd } = rental

    if (timeStart !== undefined && timeEnd !== undefined) {
      if (currentDate >= timeStart && currentDate <= timeEnd) {
        return rental
      }
    }
  })

  return currentRentals
}

export { createFetchPackageInformationLicense, convertLicensePackageInformationWithStatus, filterCurrentLicenseRented }

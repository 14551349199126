import { RobotModel } from '@/interfaces'
import * as RobotModels from '@/data/models/robotModels'

//NOTE - RobotModels ids are from horstSERVICE
export const ROBOT_MODELS: RobotModel[] = [
  RobotModels.HORST_600,
  RobotModels.HORST_600_old,
  RobotModels.HORST_600_old_2,
  RobotModels.HORST_900,
  RobotModels.HORST_1000,
  RobotModels.HORST_1400,
  RobotModels.HORST_1500,
]

import { CONTRACT_FEATURE_TYPE } from '@/enums'
import { ActiveContractFeature, RobotSystem } from '@/interfaces'
import { DateTime } from 'luxon'

/**
 *
 * @param system
 * @param type
 * @returns ActiveContractFeature | null
 */
export function getActiveFeature(system: RobotSystem, type: CONTRACT_FEATURE_TYPE): ActiveContractFeature | null {
  const ret: ActiveContractFeature = {
    active: false,
    type,
    featureStart: DateTime.utc(2999, 0, 1),
    contracts: [],
  }

  let found = false
  for (const contract of system.contracts) {
    for (const feature of contract.features) {
      if (feature.type !== type) {
        continue
      }
      ret.active = true
      found = true
      ret.contracts.push(contract)

      //calcualte start
      if (feature.featureStart) {
        ret.featureStart = DateTime.min(ret.featureStart, feature.featureStart)
      } else {
        ret.featureStart = DateTime.min(ret.featureStart, contract.start)
      }

      let endDate = undefined
      if (feature.featureEnd) {
        endDate = feature.featureEnd
      } else {
        endDate = contract.end
      }

      if (endDate) {
        if (ret.featureEnd) {
          ret.featureEnd = DateTime.max(ret.featureEnd, endDate)
        } else {
          ret.featureEnd = endDate
        }
      }
    }
  }

  if (!found) {
    return null
  }

  const now = DateTime.now()

  if (ret.featureStart > now || (ret.featureEnd && ret.featureEnd < now)) {
    ret.active = false
  }

  return ret
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1dc5a2c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app grid-y grid-frame grid-container full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_topbar = _resolveComponent("topbar")!
  const _component_navbar = _resolveComponent("navbar")!
  const _component_main_content = _resolveComponent("main-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_topbar, { class: "cell" }),
    _createVNode(_component_navbar),
    _createVNode(_component_main_content, { class: "cell auto" })
  ]))
}
/**
 * Creates href element to download Blob file
 * @param blob
 * @param fileName
 */
export function downloadBlob(blob: Blob, fileName: string): void {
  const downloadElement = document.createElement('a')
  downloadElement.href = window.URL.createObjectURL(blob)
  downloadElement.setAttribute('download', fileName)
  document.body.appendChild(downloadElement)
  downloadElement.click()
}

import { NavbarElement } from '@/interfaces'

const elementsMobile: NavbarElement[] = [
  {
    id: 'fruitcore-main',
    title: 'FRUITCORE.COM',
    icon: 'bi-fc-logo',
    externalLink: process.env.VUE_APP_FRUITCORE_ROBOTICS_HOME,
  },
  {
    id: 'horst-fx',
    title: 'horst OS / horstFX',
    icon: 'bi-fc-horstFX',
    link: '/apps/horstfxweb',
  },
]

export default elementsMobile

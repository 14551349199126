import i18n from '@/locales/i18n'
import { helpers, maxLength, minLength } from '@vuelidate/validators'
import { computed } from 'vue'

const { t } = i18n.global

export const rulesOverview = computed(() => ({
  robotName: {
    maxLength: helpers.withMessage(
      t('messages.toast.maxLength', {
        length: '254',
        inputName: t('messages.viewComponents.overview.robotName'),
      }),
      maxLength(254),
    ),
    minLength: helpers.withMessage(
      t('messages.toast.minLength', { length: '5', inputName: t('messages.viewComponents.overview.robotName') }),
      minLength(5),
    ),
  },
  robotDescription: {
    maxLength: helpers.withMessage(
      t('messages.toast.maxLength', {
        length: '1000',
        inputName: t('messages.viewComponents.overview.robotDescription'),
      }),
      maxLength(1000),
    ),
    minLength: helpers.withMessage(
      t('messages.toast.minLength', {
        length: '5',
        inputName: t('messages.viewComponents.overview.robotDescription'),
      }),
      minLength(5),
    ),
  },
}))

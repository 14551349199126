
import ConfirmationMessage from '@/components/App/ConfirmationMessage.vue'
import { componentApi } from '@/data'
import { ISidebarSection } from '@/interfaces'
import { SidebarGenerator, SidebarHandler } from '@/types'
import { valueOrGetter } from '@/utils/helpers'
import { computed, defineAsyncComponent, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'Sidebar',
  components: {
    FormRequestLicenseTicket: defineAsyncComponent(() => import('@/components/Licenses/FormRequestLicenseTicket.vue')),
    FormCreateServiceTicket: defineAsyncComponent(() => import('@/components/Service/FormCreateServiceTicket.vue')),
    BookedLicenses: defineAsyncComponent(() => import('@/components/Licenses/BookedLicenses.vue')),
    BuyDesktopLicense: defineAsyncComponent(() => import('@/components/Licenses/BuyDesktopLicense.vue')),
    ConfirmationMessage,
    componentApi,
  },
  setup() {
    const route = useRoute()
    let active = ref()
    const { t } = useI18n()
    const confirmationMessageTitle = ref(
      `${t('messages.modals.buyLicense.title')} ${t('messages.modals.buyLicense.title2')}`,
    )

    const sidebar = computed(() => {
      const sidebarGenerator: SidebarGenerator | undefined = route.meta.sidebar as SidebarGenerator | undefined
      if (!sidebarGenerator) {
        return null
      }

      return sidebarGenerator()
    })

    active = computed(() => {
      if ((route.meta.activeSidebarSection as string) !== undefined) {
        return route.meta.activeSidebarSection
      } else {
        return ''
      }
    })

    const visibleSections = computed(() =>
      sidebar.value?.sections.filter((section) => section.visible === undefined || valueOrGetter(section.visible)),
    )

    const toBindObject = (section: ISidebarSection) => {
      if (section.handler && valueOrGetter(section.enabled)) {
        return {
          click: section.handler as SidebarHandler,
        }
      }
      return {}
    }

    return {
      sidebar,
      visibleSections,
      valueOrGetter,
      toBindObject,
      active,
      confirmationMessageTitle,
    }
  },
})

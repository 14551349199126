import { LocationQueryRaw, RouteParams, Router } from 'vue-router'

/**
 *
 * @param name
 * @param router
 * @param params
 * @returns
 */
export function internalLinkDynamic<T>(
  name: string,
  router: Router,
  params: (obj: T) => RouteParams,
  query?: (obj: T) => LocationQueryRaw,
): (obj: T) => void {
  return (obj: T) => {
    router.push({ name: name, params: params ? params(obj) : undefined, query: query ? query(obj) : undefined })
  }
}

import { SidebarHandlerGenerator, ValueOrFunction } from '@/types'
import { RouteLocationNamedRaw, RouterLink } from 'vue-router'
import { valueOrGetter } from './valueOrGetter'

export function internalLink(
  name: string,
  other?: ValueOrFunction<Partial<RouteLocationNamedRaw>>,
  props?: ValueOrFunction<Record<string, unknown>>,
): SidebarHandlerGenerator {
  return {
    component: RouterLink,
    props: {
      to: {
        name,
        ...valueOrGetter(other),
      },
      ...valueOrGetter(props),
    },
  }
}

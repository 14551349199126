import { dummyOrganizationList } from '@/data/permissions/_dummy'
import { Organization } from '@/interfaces'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { pinia } from '..'
import { createLoadOrganizationsList } from './loadOrganizationsList'

export const useOrganizationsStore = defineStore(
  'organizationsStore',
  () => {
    const organizations = ref<Promise<Organization[]> | null>(null)

    return {
      organizations,
      loadOrganizationsList: createLoadOrganizationsList(organizations),
    }
  },
  {
    authenticated: {
      loadOrganizationsList: true,
    },
    dummyValue: {
      loadOrganizationsList: async (store) => {
        const orgs = dummyOrganizationList(1)
        store.organizations = Promise.resolve(orgs)
        return orgs
      },
    },
  },
)

export const injectOrganizations = async function () {
  return useOrganizationsStore(pinia).loadOrganizationsList()
}

import { Group } from '@/interfaces'
import { AuthenticationInformation, oidcEntity } from '@/oidc'
import { pinia, useUserManagementStore } from '@/stores'
import { groupBy } from 'cypress/types/lodash'
import { Organization } from 'hcosmos-api-data'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ComputedRef, reactive, Ref, ref, toRefs, watchEffect } from 'vue'

type Role = (user: AuthenticationInformation, group?: Group, organization?: Organization) => boolean

const some =
  (...roles: Role[]): Role =>
  (user, group, organization) =>
    roles.some((predicate) => predicate.call(predicate, user, group, organization))

const every =
  (...roles: Role[]): Role =>
  (user, group, organization) =>
    roles.every((predicate) => predicate.call(predicate, user, group, organization))

const both =
  (predicate: Role, role: Role): Role =>
  (user, group, organization) =>
    predicate(user, group, organization) && role(user, group, organization)

const or =
  (left: Role, right: Role): Role =>
  (user, group, organization) =>
    left(user, group, organization) || right(user, group, organization)

const not =
  (role: Role): Role =>
  (user, group, organization) =>
    !role(user, group, organization)

const isGroupAdmin: Role = (user, group) =>
  group !== undefined &&
  user.user?.profile.sub !== undefined &&
  group.userRoles.GROUP_ADMIN.includes(user.user.profile.sub)

const isOrganisationAdmin: Role = (user, group) =>
  group !== undefined &&
  user.user?.profile.sub !== undefined &&
  group.userRoles.ORGANIZATION_ADMIN.includes(user.user.profile.sub)

const isFruitcoreUser: Role = (user: AuthenticationInformation) => user.isInternalUser

const isServiceGroup: Role = (user, group) => group !== undefined && group.serviceGroup

const groupIsOrganization: Role = (_, group, org) => group !== undefined && org !== undefined && org.id === group.id

/**
 * add users To organization
 */
export const CanAddUsers = isOrganisationAdmin
export const CanRemoveUser = isOrganisationAdmin
export const CanInviteUsers = groupIsOrganization
export const CanAddSubgroups = both(not(isServiceGroup), some(isGroupAdmin, isOrganisationAdmin, isFruitcoreUser))
export const CanAddUserToGroup = some(isGroupAdmin, isOrganisationAdmin, isFruitcoreUser)
export const CanRemoveUserFromGroup = some(isGroupAdmin, isOrganisationAdmin, isFruitcoreUser)
export const CanModifyUserRoles = some(isGroupAdmin, isOrganisationAdmin, isFruitcoreUser)
export const CanModifyAdminRoles = some(isOrganisationAdmin, isFruitcoreUser)
export const CanAssignMachines = some(isGroupAdmin, isOrganisationAdmin, isFruitcoreUser)

export const CanRestRobotCredentials = isFruitcoreUser

export const CanDeleteGroup = both(
  both(not(isServiceGroup), not(groupIsOrganization)),
  some(isGroupAdmin, isOrganisationAdmin, isFruitcoreUser),
)

// both(
//   both(not(isServiceGroup), (_, group, org) => group !== undefined && org !== undefined && group.id !== org.id),
//   some(isGroupAdmin, isOrganisationAdmin, isFruitcoreUser),
// )

export const CanSeeGroup = or(isOrganisationAdmin, isGroupAdmin)
export const CanSeeGroupMembers = or(isOrganisationAdmin, isGroupAdmin)

export const CanMoveLicenses = some(isOrganisationAdmin, isGroupAdmin, isFruitcoreUser)

export type Permissions = {
  canAddUsers: boolean
  canRemoveUser: boolean
  canInviteUsers: boolean
  canAddSubgroups: boolean
  canAddUserToGroup: boolean
  canRemoveUserFromGroup: boolean
  canAssignMachines: boolean
  canDeleteGroup: boolean
  canSeeGroup: boolean
  canSeeGroupMembers: boolean
  canMoveLicenses: boolean
  canRestRobotCredentials: boolean
  canModifyUserRoles: boolean
  canModifyAdminRoles: boolean
}

export const useUsermanagementPermissions = defineStore('organisationPermissions', () => {
  const userManagementStore = useUserManagementStore()
  const { currentGroup, organization } = storeToRefs(userManagementStore)

  const permissions = computed(() => ({
    canAddUsers: CanAddUsers(oidcEntity, currentGroup.value, organization.value),
    canRemoveUser: CanRemoveUser(oidcEntity, currentGroup.value, organization.value),
    canInviteUsers: CanInviteUsers(oidcEntity, currentGroup.value, organization.value),
    canAddSubgroups: CanAddSubgroups(oidcEntity, currentGroup.value, organization.value),
    canAddUserToGroup: CanAddUserToGroup(oidcEntity, currentGroup.value, organization.value),
    canRemoveUserFromGroup: CanRemoveUserFromGroup(oidcEntity, currentGroup.value, organization.value),
    canAssignMachines: CanAssignMachines(oidcEntity, currentGroup.value, organization.value),
    canDeleteGroup: CanDeleteGroup(oidcEntity, currentGroup.value, organization.value),
    canSeeGroup: CanSeeGroup(oidcEntity, currentGroup.value, organization.value),
    canSeeGroupMembers: CanSeeGroupMembers(oidcEntity, currentGroup.value, organization.value),
    canMoveLicenses: CanMoveLicenses(oidcEntity, currentGroup.value, organization.value),
    canRestRobotCredentials: CanRestRobotCredentials(oidcEntity, currentGroup.value, organization.value),
    canModifyUserRoles: CanModifyUserRoles(oidcEntity, currentGroup.value, organization.value),
    canModifyAdminRoles: CanModifyAdminRoles(oidcEntity, currentGroup.value, organization.value),
  }))

  return {
    permissions,
  }
})

export const injectPermissions = () => storeToRefs(useUsermanagementPermissions(pinia)).permissions

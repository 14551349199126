import i18n from '@/locales/i18n'
import { PositionOption } from '@/types'
import { computed } from 'vue'

export const positionOptions = ['samePosition', 'approachingWaypoint', 'oneSpecificAxis', 'arbitraryPosition']
const { t } = i18n.global

export const computedPositionOptions = computed(() => {
  return positionOptions.map((option) => {
    return {
      label: t(`messages.modals.createServiceTicket.positionOptions.${option}`),
      value: option,
    } as PositionOption
  })
})

/**
 * From a give array of strings, if the string contains
 * any string, then it's removed from the original long string.
 * @param longString
 * @param stringArray
 */
export function removeAndReturnPartialString(longString: string, stringArray: string[]) {
  stringArray.forEach((str) => {
    longString = longString.replace(str, '')
  })
  // Remove extra spaces resulting from replacements
  longString = longString.replace(/\s+/g, '').trim()
  return longString
}

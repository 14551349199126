import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e67123c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-x" }
const _hoisted_2 = {
  id: "main-content-content",
  class: "cell auto grid-x"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_sidebar = _resolveComponent("sidebar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view, {
        class: "cell auto",
        id: "app-router-view"
      }, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "route",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    _createVNode(_Transition, { name: "sidebar" }, {
      default: _withCtx(() => [
        (_ctx.ui.isSidebarOpen)
          ? (_openBlock(), _createBlock(_component_sidebar, {
              key: 0,
              id: "main-content-sidebar",
              class: "cell max-width-sidebar-content"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
export enum LicensePackageInformationStatusTypeEnum {
  AVAILABLE = 'AVAILABLE',
  INUSE = 'INUSE',
  EXPIRED = 'EXPIRED',
  NotApplicable = 'N/A',
}

export enum DataMode {
  DUMMY,
  REAL,
}

export enum EntityState {
  UNKNOWN,
  PRODUCTION = 'PRODUCTION',
  PACKAGED = 'PACKAGED',
  ACTIVE = 'ACTIVE',
}

export enum IssueStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_CUSTOMER = 'WAITING_FOR_CUSTOMER',
  RESOLVED = 'RESOLVED',
  NotApplicable = 'N/A',
}

export enum ContractTimeRate {
  AVAILABLE = 'AVAILABLE',
  PURCHASED = 'PURCHASED',
  UNLIMITED = 'UNLIMITED',
  EXPIRED = 'EXPIRED',
}

export enum IssueCommentType {
  LOCAL = 'local',
  REMOTE = 'remote',
}

export enum DeviceOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export enum LicenseType {
  USER = 'USER',
  GROUP = 'GROUP',
}

export enum LicenseContractType {
  SOFTWARE = 'SOFTWARE',
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  TRIAL = 'TRIAL',
}

export enum TelemetryEventType {
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  OTHER = 'OTHER',
}

export enum AggregationMethod {
  MIN = 'MIN',
  MAX = 'MAX',
  MEAN = 'MEAN',
  TOTAL = 'TOTAL',
  RAW = 'RAW',
}

export enum AggregationUnit {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  MINUTELY = 'MINUTELY',
  SECONDLY = 'SECONDLY',
  RAW = 'RAW',
}

export * from './RobotSystemComponentType'
export * from './RobotSystemEventTypes'
export * from './ContractFeatureType'

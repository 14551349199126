import { Member } from '@/interfaces'
import { pinia, useUserManagementStore } from '@/stores'
import { defineStore } from 'pinia'
import { computed, Ref, ref } from 'vue'
import { createAddMembersToGroup } from './addMembersToGroup'
import { createLoadMembers } from './loadMembers'
import { createRemoveMember } from './removeMember'
import { createSetMemberRole } from './setMemberRole'

export const useMembersStore = defineStore(
  'membersStore',
  () => {
    const userManageMentStore = useUserManagementStore()

    const members = ref<Map<string, Member[]>>(new Map())

    const loadMembers = createLoadMembers(members)
    const removeGroupMember = createRemoveMember((groupId) => loadMembers(groupId, true))
    const addMembersToGroup = createAddMembersToGroup((groupId) => loadMembers(groupId, true))
    const setMemberRoles = createSetMemberRole(
      (groupId) => userManageMentStore.loadGroup(groupId, true),
      (groupId) => loadMembers(groupId, true),
    )

    const humans = (groupId: string) => members.value.get(groupId)?.filter((m) => !m.isRobot) || []
    const robots = (groupId: string) => members.value.get(groupId)?.filter((m) => m.isRobot) || []

    const findGroupByMemberId = (memberId: string): string | undefined => {
      let foundGroup: string | undefined = undefined

      members.value.forEach((val, key) => {
        if (val.length === 0) return
        if (val.find((m) => m.id === memberId) && !userManageMentStore.isOrganisation(key)) {
          foundGroup = key
        }
      })

      return foundGroup
    }

    return {
      members: humans,
      robots,
      loadMembers,
      addMembersToGroup,
      removeGroupMember,
      setMemberRoles,
      findGroupByMemberId,
    }
  },
  {
    authenticated: {
      loadMembers: true,
      addMembersToGroup: true,
      removeGroupMember: true,
      setMemberRoles: true,
    },
  },
)

export const injectMembers = (id: string) => {
  const membersStore = useMembersStore(pinia)
  return membersStore.loadMembers(id)
}

import { RobotSystem } from '@/interfaces'
import { DateTime } from 'luxon'

/**
 *
 * @param system
 * @param past
 */
export function nearestServiceIssueDate(system: RobotSystem, past: boolean): DateTime | null {
  const services = system.services
  if (services.length == 0) {
    return null
  }

  const now = DateTime.now()
  const filtered = services
    .filter((service) => (past ? service.date.startOf('day') < now : service.date.startOf('day') >= now))
    .sort((a, b) => a.date.diff(b.date, 'days').days)

  return filtered.length > 0 ? filtered[0].date : null
}

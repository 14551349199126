import { apiBusyState } from '@/data'

/**
 * Function add a dynamic busy state id.
 * Default value is true
 * @param apiBusyStateId
 * @param value
 */
export function addApiDynamicBusyState(apiBusyStateId: string, value = true) {
  apiBusyState[apiBusyStateId] = value
}

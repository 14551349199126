import { convertDateToDateTimeWithFormatOption, getDatesIntervalToHumanReadable } from '@/utils/helpers'
import { IssueCommentType } from '@/enums'
import { IssueComment } from '@/interfaces'
import { AuthenticationInformation } from '@/oidc'
import { JiraIssueComment } from 'hcosmos-api-service'
import { dataOrDefault, stringOrDefault } from '@/utils/helpers'
import { faker } from '@faker-js/faker'

export function parseIssueComment(
  jiraComment: JiraIssueComment,
  userData: AuthenticationInformation['user'],
): IssueComment {
  let type = IssueCommentType.LOCAL

  if (userData) {
    if (jiraComment.author?.emailAddress === 'service@fruitcore.de') {
      type = IssueCommentType.REMOTE
    } else {
      type = IssueCommentType.LOCAL
    }
  }

  return {
    id: dataOrDefault(jiraComment.id, faker.datatype.number({ min: 1, max: 99999 }).toString()),
    content: stringOrDefault(jiraComment.body),
    author: stringOrDefault(jiraComment.author?.displayName),
    created: jiraComment.created
      ? convertDateToDateTimeWithFormatOption(jiraComment.created)
      : convertDateToDateTimeWithFormatOption(new Date()),
    daysAgo: jiraComment.created
      ? getDatesIntervalToHumanReadable(jiraComment.created)
      : getDatesIntervalToHumanReadable(new Date()),
    type,
  }
}

import { downloadBlob } from './downloadBlob'

/**
 * Creates href element to download String as a file
 * @param content
 * @param fileName
 * @param mimeType
 */
export function downloadString(content: string, fileName: string, mimeType: string) {
  const blob = new Blob([content], { type: mimeType })
  downloadBlob(blob, fileName)
}

import licensePackageInformationWithStatusDummyValues from '@/data/packageInformationLicenses/_dummy'
import { ILicensePackageInformationWithStatus } from '@/interfaces'
import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { pinia } from '..'
import {
  createSendLicenseNotification,
  createFetchPackageInformationLicense,
  createRequestLicenseTicket,
  createRequestSignedLicenses,
  createLoadSignedSystemLicense,
} from '@/stores/packageInformationLicense'

export const usePackageInformationLicenseStore = defineStore(
  'packageInformationLicenseStore',
  () => {
    const packageInformationLicenses = ref<ILicensePackageInformationWithStatus[]>([])

    return {
      packageInformationLicenses,
      fetchPackageInformationLicenses: createFetchPackageInformationLicense(packageInformationLicenses),
      requestLicenseTicket: createRequestLicenseTicket(),
      requestSignedLicenses: createRequestSignedLicenses(),
      sendLicenseNotification: createSendLicenseNotification(),
      loadSignedLicense: createLoadSignedSystemLicense(),
    }
  },
  {
    dummyValue: {
      fetchPackageInformationLicenses: async (store) => {
        const packageInformationLicenses = await licensePackageInformationWithStatusDummyValues()
        store.packageInformationLicenses = packageInformationLicenses
        return packageInformationLicenses
      },
    },
    authenticated: {
      fetchPackageInformationLicenses: true,
      requestLicenseTicket: true,
      requestSignedLicenses: true,
      sendLicenseNotification: true,
      loadSignedLicense: true,
    },
  },
)

export type PackageInformationLicenseStore = typeof usePackageInformationLicenseStore

export const injectPackageInformationLicenses = () => {
  return usePackageInformationLicenseStore(pinia).fetchPackageInformationLicenses()
}

export const injectPackageInformationLicensesRefs = () => storeToRefs(usePackageInformationLicenseStore(pinia))


import { uiOptions } from '@/config'
import { defineComponent, computed } from 'vue'
import TopbarUserComponent from './TopbarUserComponent.vue'
import { useI18n } from 'vue-i18n'
import { enLocationOptions, deLocationOptions } from '@/config'
import { useRoute } from 'vue-router'
import { isMobile } from '@/router/utils'

export default defineComponent({
  name: 'Topbar',
  components: {
    TopbarUserComponent,
  },

  setup() {
    const ui = uiOptions()
    const i18n = useI18n()
    const route = useRoute()

    const currentPath = computed(() => {
      let isMobilePath = false
      if (route.name && isMobile()) {
        if (uiOptions().mobileRoutes.includes(route.name.toString())) {
          isMobilePath = true
        }
      }
      return isMobilePath
    })

    const handleLanguage = (e: Event) => {
      const langSelected = e.target as HTMLElement
      switch (langSelected.id) {
        case 'de':
          i18n.locale.value = 'de'
          ui.changeLanguageLocationOptions(deLocationOptions)
          break
        case 'en':
          i18n.locale.value = 'en'
          ui.changeLanguageLocationOptions(enLocationOptions)
          break
        default:
          i18n.locale.value = 'en'
          ui.changeLanguageLocationOptions(enLocationOptions)
          break
      }
    }
    return {
      currentPath,
      handleLanguage,
    }
  },
})

import { dummyGroup } from '@/data/permissions/_dummy'
import { Group, Member, Organization, Subgroup } from '@/interfaces'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { pinia } from '..'
import { createCreateGroup } from './createGroup'
import { createDeleteGroup } from './deleteGroup'
import { createGetRecommendations } from './getRecomentations'
import { createLoadGroup } from './loadGroup'
import { createLoadOrganisation } from './loadOrganisation'
import { createLoadSubgroups } from './loadSubgroups'
import { createSendInvite } from './sendInvite'

export const useUserManagementStore = defineStore(
  'userManagementStore',
  () => {
    const orgRef = ref<undefined | Organization>(undefined)
    const groups = ref<Map<string, Group>>(new Map())
    const subgroups = ref<Map<string, Subgroup[]>>(new Map())

    const recommendations = ref<Member[]>([])

    const currentGroupId = ref<null | string>(null)
    const currentGroup = computed(() => {
      return currentGroupId.value ? groups.value.get(currentGroupId.value) : undefined
    })

    const getRecommendations = createGetRecommendations(orgRef, recommendations)
    const loadOrganisation = createLoadOrganisation(orgRef, () => getRecommendations())
    const loadGroup = createLoadGroup(groups)
    const loadSubgroups = createLoadSubgroups(subgroups)
    const createGroup = createCreateGroup((id) => loadSubgroups(id, true))
    const deleteGroup = createDeleteGroup((id) => loadSubgroups(id, true))

    return {
      subgroups,
      groups,
      loadOrganisation,
      organization: orgRef,
      currentGroupId,
      currentGroup,
      recommendations,
      getRecommendations,
      loadGroup,
      loadSubgroups,
      createGroup,
      deleteGroup,
      isOrganisation: (id: string) => orgRef.value?.id === id,
      sendInvite: createSendInvite(orgRef),
    }
  },
  {
    authenticated: {
      loadOrganisation: true,
      createGroup: true,
      deleteGroup: true,
      getRecommendations: true,
      loadGroup: true,
      loadSubgroups: true,
      sendInvite: true,
    },
    dummyValue: {
      loadGroup: async (store) => {
        const group = dummyGroup()
        store.groups.set(group.id, group)
        return group
      },
    },
  },
)

export type UserManagementStore = typeof useUserManagementStore

export const injectOrganiationsRef = () => storeToRefs(useUserManagementStore(pinia))

export const injectOrganisation = (...groups: string[]) => {
  const store = useUserManagementStore(pinia)

  const orgId = groups[0]
  store.currentGroupId = groups[groups.length - 1]

  return Promise.allSettled([store.loadOrganisation(orgId), ...groups.map((g) => store.loadGroup(g))])
}

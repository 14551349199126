import { AggregationMethod, AggregationUnit } from '@/enums'
import { ProcessData, ProcessDataRecord } from '@/interfaces'
import { AggregatedStatisticsDouble, GetUserDataAggregationMethodEnum, TimestampedDataDouble } from 'hcosmos-api-data'
import { DateTime } from 'luxon'
import { aggregationMethodLookup } from './'

export function mapProcessData(data: AggregatedStatisticsDouble): ProcessData[] {
  const mappedData = {
    aggregation: {
      interval: 1,
      method:
        aggregationMethodLookup.reverse(data.aggregationMethod as GetUserDataAggregationMethodEnum) ||
        AggregationMethod.RAW,
      unit: AggregationUnit.RAW,
      //aggregationUnitLookup.reverse(data.aggregationUnit as GetUserDataAggregationUnitEnum) || AggregationUnit.RAW,
    },
    start: DateTime.fromJSDate(data.start || new Date()),
    end: DateTime.fromJSDate(data.end || new Date()),
  }
  return Object.entries(mapData(data.data || {})).map(([category, value]) => ({ ...mappedData, category, data: value }))
}

function mapData(data: { [category: string]: { [key: string]: Array<TimestampedDataDouble> } }): {
  [category: string]: { [key: string]: ProcessDataRecord[] }
} {
  const mapKeyData = ([key, data]: [string, TimestampedDataDouble[]]): [string, ProcessDataRecord[]] => [
    key,
    data.map((entry) => mapTimestamp(key, entry)),
  ]

  const mappedEntries = Object.entries(data).map(([category, keyData]) => [
    category,
    Object.fromEntries(Object.entries(keyData).map(mapKeyData)),
  ])

  return Object.fromEntries(mappedEntries)
}

function mapTimestamp(key: string, value: TimestampedDataDouble): ProcessDataRecord {
  return {
    key,
    timestamp: DateTime.fromISO(value.timestamp! as unknown as string),
    value: value.value,
  }
}

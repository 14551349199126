import { reactive } from 'vue'

export const apiBusyState = reactive<Record<string, boolean>>({
  system: false,
  issues: false,
  packageInformationLicenses: false,
  component: false,
  internal: true,
  processData: false,
  selectedIssue: false,
  submittingForm: false,
  addAttachments: false,
})
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function apiStates() {
  return apiBusyState
}


import { defineComponent } from 'vue'

import { uiOptions } from '@/config'
import { authenticationInformation } from '@/oidc/authentication'
import Sidebar from '@/components/App/Sidebar'

export default defineComponent({
  components: {
    Sidebar,
  },
  name: 'Content',
  setup() {
    return {
      ui: uiOptions(),
      ...authenticationInformation(),
    }
  },
})

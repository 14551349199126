
import { computed, defineComponent, inject, ref, watch } from 'vue'
import { authenticationInformation } from '@/oidc/authentication'
import { useOrganizationsStore } from '@/stores'
import { storeToRefs } from 'pinia'

export default defineComponent({
  name: 'TopbarUserComponent',
  setup() {
    const user = authenticationInformation().user
    const orgStore = useOrganizationsStore()
    const { organizations } = storeToRefs(orgStore)

    const featureFlags: { userManagementEnabled: boolean } | undefined = inject('featureFlags')

    const loginButton = () => {
      if (!user?.isAuthenticated) {
        user?.login()
      } else {
        user?.logout()
      }
    }

    const isAdminOfOrg = ref<boolean>(false)

    watch(organizations, async (newOrg) => {
      const orgs = await newOrg
      isAdminOfOrg.value = orgs !== null && orgs?.length > 0
    })

    return {
      user,
      isAdminOfOrg,
      featureFlags,
      loginButton,
    }
  },
})
